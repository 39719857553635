/*
 * @Author: 冯杰
 * @Date: 2021-09-23 14:36:39
 * @LastEditTime: 2021-11-08 14:26:38
 * @FileName: 协议阶梯管理列表
 */
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Add from './form/index.js';

export default {
  name: 'TerminalList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps-mobile/quotaRandomMaintain/findByConditions',
      requestType: 'POST',
    };
  },
  components: {
    Add,
    // Access,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.formName = 'Add';
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('random_list');
  },
};
