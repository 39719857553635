var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.ladderType === "display" ? "dis" : "qua" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("span", { staticClass: "iconcalss" }, [_vm._v("*")]),
          _c("span", [_vm._v("包量阶梯销量目标")]),
          _c(
            "el-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [_vm._v("月度")]
          ),
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            border: "",
            "show-header-overflow": "",
            "show-overflow": "",
            "highlight-hover-row": "",
            data: _vm.tableInfo.data,
            "show-footer": "",
            "edit-config": { trigger: "click", mode: "row" },
          },
        },
        _vm._l(_vm.tableInfo.columns, function (col, index) {
          return _c("vxe-table-column", {
            key: index,
            attrs: {
              field: col.field,
              title: col.title,
              "edit-render":
                col.editRender &&
                Object.assign({}, col.editRender, {
                  props: Object.assign({}, col.editRender.props, {
                    disabled: _vm.tableDisable(col.field),
                  }),
                }),
              "min-width": col.width ? col.width : 80,
              fixed: col.fixed,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }