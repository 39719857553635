/*
 * @Author: 冯杰
 * @Date: 2021-09-23 16:33:35
 * @LastEditTime: 2021-11-08 15:29:12
 * @FileName: 新增协议阶梯
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DetailVos from '../../../components/arrange.vue';

formCreate.component('DetailVos', DetailVos);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'random_form',
    };
  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'add') {
        this.hiddenFields(true, ['singleRandomCount']);
      }
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/cps-mobile/quotaRandomMaintain/findById', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            console.log(res.result);
            if (res.result.randomType === 'false_random') {
              this.hiddenFields(false, 'singleRandomCount');
            } else {
              this.hiddenFields(true, 'singleRandomCount');
            }
            this.setValue(res.result);
          }
        });
      }
      const randomType = this.getRule('randomType');
      randomType.on = {
        ...randomType.on,
        change: (name) => {
          if (name === 'false_random') {
            this.hiddenFields(false, 'singleRandomCount');
          } else {
            this.hiddenFields(true, 'singleRandomCount');
          }
        },
      };
    },

    setRule(item) {
      const val = item;
      if (val.field === 'singleRandomCount') {
        val.props = {
          ...val.props,
          min: 0,
          precision: 0,
          controls: false,
        };
      }
      if (val.field === 'detailVos') {
        val.value = [];
        val.props = {
          ...val.props,
          showFooter: true,
        };
        val.on = {
          ...val.on,
          totalData: (row) => {
            this.setValue({
              weightedAvg: row,
            });
          },
        };
        // val.value = [];
      }
      return val;
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      const subData = { ...formData };
      let url = '/cps-mobile/quotaRandomMaintain/create';
      if (this.formConfig.code === 'edit') {
        url = '/cps-mobile/quotaRandomMaintain/update';
        subData.id = this.formConfig.row.id;
        subData.randomCode = this.formConfig.row.randomCode;
      }
      request.post(url, subData).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
