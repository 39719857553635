var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "box-sizing" }, [
      _vm._m(0),
      _c("div", { staticClass: "text-main" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _vm._v(" 红包提现门槛 "),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.dealer.threshold.thresholdDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.dealer.threshold.thresholdInput,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealer.threshold, "thresholdInput", $$v)
                        },
                        expression: "dealer.threshold.thresholdInput",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.thresholdCheckedChange("dealer")
                          },
                        },
                        model: {
                          value: _vm.dealer.threshold.thresholdChecked,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.threshold,
                              "thresholdChecked",
                              $$v
                            )
                          },
                          expression: "dealer.threshold.thresholdChecked",
                        },
                      },
                      [_vm._v("不限制")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _vm._v(" 单次红包提现金额限制 "),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.dealer.threshold.restrictDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.dealer.threshold.restrictInput,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealer.threshold, "restrictInput", $$v)
                        },
                        expression: "dealer.threshold.restrictInput",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.restrictCheckedChange("dealer")
                          },
                        },
                        model: {
                          value: _vm.dealer.threshold.restrictChecked,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.threshold,
                              "restrictChecked",
                              $$v
                            )
                          },
                          expression: "dealer.threshold.restrictChecked",
                        },
                      },
                      [_vm._v("不限制")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现金额上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.amountChange("dealer")
                  },
                },
                model: {
                  value: _vm.dealer.checked.amountChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.dealer.checked, "amountChecked", $$v)
                  },
                  expression: "dealer.checked.amountChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              { class: _vm.jia },
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.dealer.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.dealer.maximumAmount.moneySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.maximumAmount,
                              "moneySelectJia",
                              $$v
                            )
                          },
                          expression: "dealer.maximumAmount.moneySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.dealer.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.dealer.maximumAmount.moneyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dealer.maximumAmount,
                            "moneyInputJia",
                            $$v
                          )
                        },
                        expression: "dealer.maximumAmount.moneyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowAmount("dealer")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { class: _vm.jian },
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.dealer.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.dealer.maximumAmount.moneySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.maximumAmount,
                              "moneySelectJian",
                              $$v
                            )
                          },
                          expression: "dealer.maximumAmount.moneySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.dealer.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.dealer.maximumAmount.moneyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dealer.maximumAmount,
                            "moneyInputJian",
                            $$v
                          )
                        },
                        expression: "dealer.maximumAmount.moneyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowAmount("dealer", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现次数上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.frequencyChange("dealer")
                  },
                },
                model: {
                  value: _vm.dealer.checked.frequencyChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.dealer.checked, "frequencyChecked", $$v)
                  },
                  expression: "dealer.checked.frequencyChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.dealer.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.dealer.frequency.frequencySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.frequency,
                              "frequencySelectJia",
                              $$v
                            )
                          },
                          expression: "dealer.frequency.frequencySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.dealer.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.dealer.frequency.frequencyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dealer.frequency,
                            "frequencyInputJia",
                            $$v
                          )
                        },
                        expression: "dealer.frequency.frequencyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowFrequency("dealer")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.dealer.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.dealer.frequency.frequencySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dealer.frequency,
                              "frequencySelectJian",
                              $$v
                            )
                          },
                          expression: "dealer.frequency.frequencySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.dealer.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.dealer.frequency.frequencyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dealer.frequency,
                            "frequencyInputJian",
                            $$v
                          )
                        },
                        expression: "dealer.frequency.frequencyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowFrequency("dealer", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._m(2),
        _c(
          "div",
          { staticClass: "text-textarea" },
          [
            _c("el-input", {
              staticClass: "input-textarea",
              attrs: { type: "textarea", placeholder: "请输入红包提现规则" },
              model: {
                value: _vm.dealer.dealerText,
                callback: function ($$v) {
                  _vm.$set(_vm.dealer, "dealerText", $$v)
                },
                expression: "dealer.dealerText",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "box-sizing" }, [
      _vm._m(3),
      _c("div", { staticClass: "text-main" }, [
        _vm._m(4),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _vm._v(" 红包提现门槛 "),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.terminal.threshold.thresholdDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.terminal.threshold.thresholdInput,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.terminal.threshold,
                            "thresholdInput",
                            $$v
                          )
                        },
                        expression: "terminal.threshold.thresholdInput",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.thresholdCheckedChange("terminal")
                          },
                        },
                        model: {
                          value: _vm.terminal.threshold.thresholdChecked,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.threshold,
                              "thresholdChecked",
                              $$v
                            )
                          },
                          expression: "terminal.threshold.thresholdChecked",
                        },
                      },
                      [_vm._v("不限制")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _vm._v(" 单次红包提现金额限制 "),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.terminal.threshold.restrictDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.terminal.threshold.restrictInput,
                        callback: function ($$v) {
                          _vm.$set(_vm.terminal.threshold, "restrictInput", $$v)
                        },
                        expression: "terminal.threshold.restrictInput",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.restrictCheckedChange("terminal")
                          },
                        },
                        model: {
                          value: _vm.terminal.threshold.restrictChecked,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.threshold,
                              "restrictChecked",
                              $$v
                            )
                          },
                          expression: "terminal.threshold.restrictChecked",
                        },
                      },
                      [_vm._v("不限制")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现金额上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.amountChange("terminal")
                  },
                },
                model: {
                  value: _vm.terminal.checked.amountChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.terminal.checked, "amountChecked", $$v)
                  },
                  expression: "terminal.checked.amountChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.terminal.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.terminal.maximumAmount.moneySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.maximumAmount,
                              "moneySelectJia",
                              $$v
                            )
                          },
                          expression: "terminal.maximumAmount.moneySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.terminal.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.terminal.maximumAmount.moneyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.terminal.maximumAmount,
                            "moneyInputJia",
                            $$v
                          )
                        },
                        expression: "terminal.maximumAmount.moneyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowAmount("terminal")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.terminal.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.terminal.maximumAmount.moneySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.maximumAmount,
                              "moneySelectJian",
                              $$v
                            )
                          },
                          expression: "terminal.maximumAmount.moneySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.terminal.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.terminal.maximumAmount.moneyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.terminal.maximumAmount,
                            "moneyInputJian",
                            $$v
                          )
                        },
                        expression: "terminal.maximumAmount.moneyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowAmount("terminal", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现次数上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.frequencyChange("terminal")
                  },
                },
                model: {
                  value: _vm.terminal.checked.frequencyChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.terminal.checked, "frequencyChecked", $$v)
                  },
                  expression: "terminal.checked.frequencyChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.terminal.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.terminal.frequency.frequencySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.frequency,
                              "frequencySelectJia",
                              $$v
                            )
                          },
                          expression: "terminal.frequency.frequencySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.terminal.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.terminal.frequency.frequencyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.terminal.frequency,
                            "frequencyInputJia",
                            $$v
                          )
                        },
                        expression: "terminal.frequency.frequencyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowFrequency("terminal")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.terminal.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.terminal.frequency.frequencySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.terminal.frequency,
                              "frequencySelectJian",
                              $$v
                            )
                          },
                          expression: "terminal.frequency.frequencySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.terminal.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.terminal.frequency.frequencyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.terminal.frequency,
                            "frequencyInputJian",
                            $$v
                          )
                        },
                        expression: "terminal.frequency.frequencyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowFrequency("terminal", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._m(5),
        _c(
          "div",
          { staticClass: "text-textarea" },
          [
            _c("el-input", {
              staticClass: "input-textarea",
              attrs: { type: "textarea", placeholder: "请输入红包提现规则" },
              model: {
                value: _vm.terminal.terminalText,
                callback: function ($$v) {
                  _vm.$set(_vm.terminal, "terminalText", $$v)
                },
                expression: "terminal.terminalText",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "box-sizing" }, [
      _vm._m(6),
      _c("div", { staticClass: "text-main" }, [
        _vm._m(7),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _vm._v(" 单次红包提现金额限制 "),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.consumer.threshold.restrictDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.consumer.threshold.restrictInput,
                        callback: function ($$v) {
                          _vm.$set(_vm.consumer.threshold, "restrictInput", $$v)
                        },
                        expression: "consumer.threshold.restrictInput",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.restrictCheckedChange("consumer")
                          },
                        },
                        model: {
                          value: _vm.consumer.threshold.restrictChecked,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.consumer.threshold,
                              "restrictChecked",
                              $$v
                            )
                          },
                          expression: "consumer.threshold.restrictChecked",
                        },
                      },
                      [_vm._v("不限制")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现金额上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.amountChange("consumer")
                  },
                },
                model: {
                  value: _vm.consumer.checked.amountChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.consumer.checked, "amountChecked", $$v)
                  },
                  expression: "consumer.checked.amountChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.consumer.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.consumer.maximumAmount.moneySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.consumer.maximumAmount,
                              "moneySelectJia",
                              $$v
                            )
                          },
                          expression: "consumer.maximumAmount.moneySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.consumer.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.consumer.maximumAmount.moneyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.consumer.maximumAmount,
                            "moneyInputJia",
                            $$v
                          )
                        },
                        expression: "consumer.maximumAmount.moneyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowAmount("consumer")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.consumer.checked.amountDisabled,
                        },
                        model: {
                          value: _vm.consumer.maximumAmount.moneySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.consumer.maximumAmount,
                              "moneySelectJian",
                              $$v
                            )
                          },
                          expression: "consumer.maximumAmount.moneySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        precision: 2,
                        step: 0.01,
                        disabled: _vm.consumer.checked.amountDisabled,
                        controls: false,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.consumer.maximumAmount.moneyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.consumer.maximumAmount,
                            "moneyInputJian",
                            $$v
                          )
                        },
                        expression: "consumer.maximumAmount.moneyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("元")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowAmount("consumer", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-title" },
          [
            _c("img", {
              attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
            }),
            _vm._v(" 红包提现次数上限设置 "),
            _c(
              "el-checkbox",
              {
                on: {
                  change: function ($event) {
                    return _vm.frequencyChange("consumer")
                  },
                },
                model: {
                  value: _vm.consumer.checked.frequencyChecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.consumer.checked, "frequencyChecked", $$v)
                  },
                  expression: "consumer.checked.frequencyChecked",
                },
              },
              [_vm._v("不限制")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-contion" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.consumer.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.consumer.frequency.frequencySelectJia,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.consumer.frequency,
                              "frequencySelectJia",
                              $$v
                            )
                          },
                          expression: "consumer.frequency.frequencySelectJia",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.consumer.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.consumer.frequency.frequencyInputJia,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.consumer.frequency,
                            "frequencyInputJia",
                            $$v
                          )
                        },
                        expression: "consumer.frequency.frequencyInputJia",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-plus icon-style-jia",
                    on: {
                      click: function ($event) {
                        return _vm.addRowFrequency("consumer")
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 2 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.consumer.checked.frequencyDisabled,
                        },
                        model: {
                          value: _vm.consumer.frequency.frequencySelectJian,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.consumer.frequency,
                              "frequencySelectJian",
                              $$v
                            )
                          },
                          expression: "consumer.frequency.frequencySelectJian",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 1 } },
                  [_vm._v(" 不超过 ")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        controls: false,
                        disabled: _vm.consumer.checked.frequencyDisabled,
                      },
                      model: {
                        value: _vm.consumer.frequency.frequencyInputJian,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.consumer.frequency,
                            "frequencyInputJian",
                            $$v
                          )
                        },
                        expression: "consumer.frequency.frequencyInputJian",
                      },
                    }),
                    _c("span", [_vm._v("次")]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("i", {
                    staticClass: "el-icon-minus icon-style-jian",
                    on: {
                      click: function ($event) {
                        return _vm.delRowFrequency("consumer", _vm.index)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._m(8),
        _c(
          "div",
          { staticClass: "text-textarea" },
          [
            _c("el-input", {
              staticClass: "input-textarea",
              attrs: { type: "textarea", placeholder: "请输入红包提现规则" },
              model: {
                value: _vm.consumer.consumerText,
                callback: function ($$v) {
                  _vm.$set(_vm.consumer, "consumerText", $$v)
                },
                expression: "consumer.consumerText",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "box-sizing submit-right" },
      [
        _c("el-button", { attrs: { type: "primary" } }, [
          _c("i", { staticClass: "el-icon-circle-check" }),
          _vm._v("保存"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/hbicon.png") },
      }),
      _vm._v(" 经销商红包权益配置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 提现门槛及单次提现红包金额设置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 红包提现规则 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/hbicon.png") },
      }),
      _vm._v(" 终端门店红包权益配置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 提现门槛及单次提现红包金额设置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 红包提现规则 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/hbicon.png") },
      }),
      _vm._v(" 消费者红包权益配置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 提现门槛及单次提现红包金额设置 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        attrs: { src: require("@/found/assets/img/cpsimg/shutiao.png") },
      }),
      _vm._v(" 红包提现规则 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }