/*
 * @Author: 冯杰
 * @Date: 2021-09-23 14:36:39
 * @LastEditTime: 2021-11-08 14:26:38
 * @FileName: 协议阶梯管理列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Add from './form/index.js';

// import Access from './form/component/access/access.js';

export default {
  name: 'TerminalList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/agreement/agreementLadder/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Add,
    // Access,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      const id = '';
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'particulars') {
        // 详情
        this.modalConfig.title = '详情';
        this.formName = 'Add';
        this.formConfig.row = row;
        this.formConfig.state = 'particulars';
        this.openFull();
      } else if (val.code === 'export') {
        // 导出
      } else if (val.code === 'add') {
        // 新增
        this.modalConfig.title = '新增协议阶梯';
        this.formName = 'Add';
        this.formConfig.buttonCode = 'add';
        this.openFull();
      }
      if (val.code === 'span-qi') {
        // 启用
        urls = '/cps/v1/agreement/agreementLadder/enable';
        text = '启用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      } else if (val.code === 'span-jin') {
        // 禁用
        urls = '/cps/v1/agreement/agreementLadder/disable';
        text = '禁用';
        if (row.id) {
          this.selection.push(row.id);
        }
        this.operationClick(urls, text);
      }
    },
    // 启用、禁用操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
    clickVisible({ val, row }) {
      if (row.enableStatus === '启用') {
        if (val.buttonName === '启用-') {
          return false;
        }
      }
      if (row.enableStatus === '禁用') {
        if (val.buttonName === '禁用-') {
          return false;
        }
      }
      return true;
    },
    // 对列表数据进行二次封装
    afterGetList() {
      if (this.tableData.length > 0) {
        const newTableData = this.tableData.map((item) => ({
          ...item,
          enableStatus: item.enableStatus === '003' ? '禁用' : '启用',
        }));
        this.tableData = newTableData;
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('protocolLadderList');
  },
};
