<!--
 * @Author: 冯杰
 * @Date: 2021-08-19 14:28:25
 * @LastEditTime: 2021-09-28 15:26:24
 * @FileName: 红包权益配置
-->
<template>
  <div class="box">
    <div class="box-sizing">
      <div class="text">
        <img src="~@/found/assets/img/cpsimg/hbicon.png" />
        经销商红包权益配置
      </div>
      <div class="text-main">
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          提现门槛及单次提现红包金额设置
          </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              红包提现门槛
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="dealer.threshold.thresholdInput" :precision="2" :step="0.01"  :disabled="dealer.threshold.thresholdDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="dealer.threshold.thresholdChecked"
                @change="thresholdCheckedChange('dealer')"
                >不限制</el-checkbox
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              单次红包提现金额限制
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="dealer.threshold.restrictInput" :precision="2" :step="0.01"  :disabled="dealer.threshold.restrictDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="dealer.threshold.restrictChecked"
                @change="restrictCheckedChange('dealer')"
                >不限制</el-checkbox
              >
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现金额上限设置
          <el-checkbox v-model="dealer.checked.amountChecked" @change="amountChange('dealer')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row :class="jia">
            <el-col :span="2">
              <el-select
                v-model="dealer.maximumAmount.moneySelectJia"
                placeholder="请选择"
                :disabled="dealer.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="dealer.maximumAmount.moneyInputJia" :precision="2" :step="0.01"  :disabled="dealer.checked.amountDisabled" :controls="false"  placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowAmount('dealer')"></i>
            </el-col>
          </el-row>
          <el-row :class="jian">
            <el-col :span="2">
              <el-select
                v-model="dealer.maximumAmount.moneySelectJian"
                placeholder="请选择"
                :disabled="dealer.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="dealer.maximumAmount.moneyInputJian" :precision="2" :step="0.01"  :disabled="dealer.checked.amountDisabled" :controls="false"  placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-minus icon-style-jian" @click="delRowAmount('dealer', index)"></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现次数上限设置
          <el-checkbox v-model="dealer.checked.frequencyChecked" @change="frequencyChange('dealer')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="dealer.frequency.frequencySelectJia"
                placeholder="请选择"
                :disabled="dealer.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="dealer.frequency.frequencyInputJia"
                clearable
                :controls="false"
                :disabled="dealer.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowFrequency('dealer')"></i>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="dealer.frequency.frequencySelectJian"
                placeholder="请选择"
                :disabled="dealer.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="dealer.frequency.frequencyInputJian"
                clearable
                :controls="false"
                :disabled="dealer.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i
                class="el-icon-minus icon-style-jian"
                @click="delRowFrequency('dealer', index)"
              ></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现规则
          </div>
        <div class="text-textarea">
          <el-input
            class="input-textarea"
            type="textarea"
            placeholder="请输入红包提现规则"
            v-model="dealer.dealerText"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="box-sizing">
      <div class="text">
        <img src="~@/found/assets/img/cpsimg/hbicon.png" />
        终端门店红包权益配置
      </div>
      <div class="text-main">
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          提现门槛及单次提现红包金额设置
          </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              红包提现门槛
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="terminal.threshold.thresholdInput" :precision="2" :step="0.01"  :disabled="terminal.threshold.thresholdDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="terminal.threshold.thresholdChecked"
                @change="thresholdCheckedChange('terminal')"
                >不限制</el-checkbox
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              单次红包提现金额限制
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="terminal.threshold.restrictInput" :precision="2" :step="0.01"  :disabled="terminal.threshold.restrictDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="terminal.threshold.restrictChecked"
                @change="restrictCheckedChange('terminal')"
                >不限制</el-checkbox
              >
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现金额上限设置
          <el-checkbox v-model="terminal.checked.amountChecked" @change="amountChange('terminal')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="terminal.maximumAmount.moneySelectJia"
                placeholder="请选择"
                :disabled="terminal.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="terminal.maximumAmount.moneyInputJia" :precision="2" :step="0.01"  :disabled="terminal.checked.amountDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowAmount('terminal')"></i>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="terminal.maximumAmount.moneySelectJian"
                placeholder="请选择"
                :disabled="terminal.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="terminal.maximumAmount.moneyInputJian" :precision="2" :step="0.01"  :disabled="terminal.checked.amountDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-minus icon-style-jian" @click="delRowAmount('terminal', index)"></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现次数上限设置
          <el-checkbox
            v-model="terminal.checked.frequencyChecked"
            @change="frequencyChange('terminal')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="terminal.frequency.frequencySelectJia"
                placeholder="请选择"
                :disabled="terminal.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="terminal.frequency.frequencyInputJia"
                clearable
                :controls="false"
                :disabled="terminal.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowFrequency('terminal')"></i>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="terminal.frequency.frequencySelectJian"
                placeholder="请选择"
                :disabled="terminal.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="terminal.frequency.frequencyInputJian"
                clearable
                :controls="false"
                :disabled="terminal.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i
                class="el-icon-minus icon-style-jian"
                @click="delRowFrequency('terminal', index)"
              ></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现规则
          </div>
        <div class="text-textarea">
          <el-input
            class="input-textarea"
            type="textarea"
            placeholder="请输入红包提现规则"
            v-model="terminal.terminalText"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="box-sizing">
      <div class="text">
        <img src="~@/found/assets/img/cpsimg/hbicon.png" />
        消费者红包权益配置
      </div>
      <div class="text-main">
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          提现门槛及单次提现红包金额设置
          </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              单次红包提现金额限制
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="consumer.threshold.restrictInput" :precision="2" :step="0.01"  :disabled="consumer.threshold.restrictDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="consumer.threshold.restrictChecked"
                @change="restrictCheckedChange('consumer')"
                >不限制</el-checkbox
              >
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现金额上限设置
          <el-checkbox v-model="consumer.checked.amountChecked" @change="amountChange('consumer')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="consumer.maximumAmount.moneySelectJia"
                placeholder="请选择"
                :disabled="consumer.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="consumer.maximumAmount.moneyInputJia" :precision="2" :step="0.01"  :disabled="consumer.checked.amountDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowAmount('consumer')"></i>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="consumer.maximumAmount.moneySelectJian"
                placeholder="请选择"
                :disabled="consumer.checked.amountDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number v-model="consumer.maximumAmount.moneyInputJian" :precision="2" :step="0.01"  :disabled="consumer.checked.amountDisabled" :controls="false" placeholder="请输入内容"></el-input-number>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-minus icon-style-jian" @click="delRowAmount('consumer', index)"></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现次数上限设置
          <el-checkbox
            v-model="consumer.checked.frequencyChecked"
            @change="frequencyChange('consumer')"
            >不限制</el-checkbox
          >
        </div>
        <div class="text-contion">
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="consumer.frequency.frequencySelectJia"
                placeholder="请选择"
                :disabled="consumer.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="consumer.frequency.frequencyInputJia"
                clearable
                :controls="false"
                :disabled="consumer.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-plus icon-style-jia" @click="addRowFrequency('consumer')"></i>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <el-select
                v-model="consumer.frequency.frequencySelectJian"
                placeholder="请选择"
                :disabled="consumer.checked.frequencyDisabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1" class="text-center">
              不超过
            </el-col>
            <el-col :span="4">
              <el-input-number
                placeholder="请输入内容"
                v-model="consumer.frequency.frequencyInputJian"
                clearable
                :controls="false"
                :disabled="consumer.checked.frequencyDisabled"
              ></el-input-number>
              <span>次</span>
            </el-col>
            <el-col :span="1">
              <i
                class="el-icon-minus icon-style-jian"
                @click="delRowFrequency('consumer', index)"
              ></i>
            </el-col>
          </el-row>
        </div>
        <div class="text-title">
          <img src="~@/found/assets/img/cpsimg/shutiao.png">
          红包提现规则
          </div>
        <div class="text-textarea">
          <el-input
            class="input-textarea"
            type="textarea"
            placeholder="请输入红包提现规则"
            v-model="consumer.consumerText"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="box-sizing submit-right">
      <el-button type="primary"><i class="el-icon-circle-check"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$el.data);
  },
  data() {
    return {
      dealer: {
        checked: {
          amountChecked: false,
          amountDisabled: false,
          frequencyChecked: false,
          frequencyDisabled: false,
        },
        threshold: {
          thresholdInput: '',
          thresholdDisabled: true,
          thresholdChecked: true,
          restrictInput: '',
          restrictDisabled: false,
          restrictChecked: false,
        },
        maximumAmount: [
          {
            moneySelectJia: '',
            moneySelectJian: '',
            moneyInputJia: '',
            moneyInputJian: '',
          },
        ],
        frequency: [
          {
            frequencySelectJia: '',
            frequencySelectJian: '',
            frequencyInputJia: '',
            frequencyInputJian: '',
          },
        ],
        dealerText: '',
      },
      terminal: {
        checked: {
          amountChecked: false,
          amountDisabled: false,
          frequencyChecked: false,
          frequencyDisabled: false,
        },
        threshold: {
          thresholdInput: '',
          thresholdDisabled: true,
          thresholdChecked: true,
          restrictInput: '',
          restrictDisabled: false,
          restrictChecked: false,
        },
        maximumAmount: [
          {
            moneySelectJia: '',
            moneySelectJian: '',
            moneyInputJia: '',
            moneyInputJian: '',
          },
        ],
        frequency: [
          {
            frequencySelectJia: '',
            frequencySelectJian: '',
            frequencyInputJia: '',
            frequencyInputJian: '',
          },
        ],
        terminalText: '',
      },
      consumer: {
        checked: {
          amountChecked: false,
          amountDisabled: false,
          frequencyChecked: false,
          frequencyDisabled: false,
        },
        threshold: {
          thresholdInput: '',
          restrictInput: '',
          restrictDisabled: true,
          restrictChecked: true,
        },
        maximumAmount: [
          {
            moneySelectJia: '',
            moneyInputJia: '',
            moneySelectJian: '',
            moneyInputJian: '',
          },
        ],
        frequency: [
          {
            frequencySelectJia: '',
            frequencyInputJia: '',
            frequencySelectJian: '',
            frequencyInputJian: '',
          },
        ],
        consumerText: '',
      },
      options: [{
        value: '选项1',
        label: '每天',
      }, {
        value: '选项2',
        label: '每月',
      }],
    };
  },
  methods: {
    thresholdCheckedChange(str) {
      const $keyword = str;
      if (this[$keyword].threshold.thresholdChecked) {
        this[$keyword].threshold.thresholdDisabled = true;
      } else {
        this[$keyword].threshold.thresholdDisabled = false;
      }
    },
    restrictCheckedChange(str) {
      const $keyword = str;
      if (this[$keyword].threshold.restrictChecked) {
        this[$keyword].threshold.restrictDisabled = true;
      } else {
        this[$keyword].threshold.restrictDisabled = false;
      }
    },
    amountChange(str) {
      const $keyword = str;
      if (this[$keyword].checked.amountChecked) {
        this[$keyword].checked.amountDisabled = true;
      } else {
        this[$keyword].checked.amountDisabled = false;
      }
    },
    frequencyChange(str) {
      const $keyword = str;
      if (this[$keyword].checked.frequencyChecked) {
        this[$keyword].checked.frequencyDisabled = true;
      } else {
        this[$keyword].checked.frequencyDisabled = false;
      }
    },
    // addRowAmount(str) {
    //   const $keyword = str;
    //   this[$keyword].maximumAmount.push({
    //     moneySelect: '',
    //     moneyInput: '',
    //   });
    // },
    // addRowFrequency(str) {
    //   const $keyword = str;
    //   this[$keyword].frequency.push({
    //     frequencySelect: '',
    //     frequencyInput: '',
    //   });
    // },
    // delRowAmount(str, num) {
    //   const $keyword = str;
    //   this[$keyword].maximumAmount.splice(num, 1);
    // },
    // delRowFrequency(str, num) {
    //   const $keyword = str;
    //   this[$keyword].frequency.splice(num, 1);
    // },
  },
};
</script>

<style lang="less" scoped>
.jian{
  display: none;
}
.box {
  min-width: 1548px;
  background-color: #f9f9f9;
  .box-sizing {
    margin: 0 14px;
  }
  .text {
    padding: 14px 20px 14px 0;
    line-height: 25px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #53a8ff;
    letter-spacing: 0;
    font-weight: 500;
    vertical-align: auto;
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      object-fit: contain;
    }
  }
  .text-main {
    background: #ffffff;
    border-radius: 4px;
    padding: 0 20px 30px;
    .text-title {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 500;
      padding: 24px 0 14px;
      img{
      vertical-align: middle;
      margin-right: 6px;
      object-fit: scale-down;
      }
      .el-checkbox {
        margin-left: 34px;
      }
    }
    .text-contion {
      background: #f9f9f9;
      border-radius: 4px;
      padding: 20px 0 20px 20px;
      .el-input-number,
      .el-input {
        width: 194px;
        height: 28px;
        background: #ffffff;
        border-radius: 2px;
        margin-right: 6px;
      }
      /deep/ .el-input__inner {
        height: 28px;
        line-height: 28px;
      }
      span {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #333333;
      }
      .el-row {
        line-height: 28px;
        margin-bottom: 14px;
        :last-child {
          margin-bottom: 0;
        }
        .text-center {
          text-align: center;
        }
        .icon-style-jian {
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #f66046;
          border-radius: 2px;
          color: #fff;
        }
        .icon-style-jia {
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #333333;
          border-radius: 2px;
          color: #fff;
        }
      }
      .el-row:last-child {
        margin-bottom: 0;
      }
    }
    .contion-height {
      height: 68px;
    }
    .text-textarea {
      height: 226px;
      background: #ffffff;
      border-radius: 4px;
      .input-textarea {
        height: 100%;
        margin-bottom: 30px;
      }
      /deep/ .el-textarea__inner {
        height: 100% !important;
      }
    }
  }
  .el-button {
    width: 96px;
    height: 36px;
    background: #2e7bff;
    border-radius: 4px;
    margin: 20px 0 26px;
  }
  .submit-right {
    text-align: right;
    i {
      margin-right: 8px;
    }
  }
}
</style>
