<!--
 * @Author: 冯杰
 * @Date: 2021-09-23 15:44:48
 * @LastEditTime: 2021-11-08 15:22:34
 * @FileName: 新增包量协议阶梯
-->
<template>
    <div :class="ladderType === 'display'?'dis':'qua'">
      <div class="title">
        <span class="iconcalss">*</span>
        <span>包量阶梯销量目标</span>
        <el-radio v-model="radio" label="1">月度</el-radio>
      </div>
      <vxe-table
            border
            ref="xTable"
            show-header-overflow
            show-overflow
            highlight-hover-row
            :data="tableInfo.data"
            show-footer
            :edit-config="{ trigger: 'click', mode: 'row' }"
          >
            <vxe-table-column
              v-for="(col, index) in tableInfo.columns"
              :key="index"
              :field="col.field"
              :title="col.title"
              :edit-render="
                col.editRender && {
                  ...col.editRender,
                  props: {
                    ...col.editRender.props,
                    disabled: tableDisable(col.field)
                  }
                }
              "
              :min-width="col.width ? col.width : 80"
              :fixed="col.fixed"
            >
            </vxe-table-column>
          </vxe-table>
    </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      tableInfo: {
        columns: [
          {
            field: 'yearSaleNum',
            title: '总销量目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.targetNumInput } },
          },
          {
            field: 'januarySaleNum',
            title: '月度1目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'februarySaleNum',
            title: '月度2目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'marchSaleNum',
            title: '月度3目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'aprilSaleNum',
            title: '月度4目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'maySaleNum',
            title: '月度5目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'juneSaleNum',
            title: '月度6目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'julySaleNum',
            title: '月度7目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'augustSaleNum',
            title: '月度8目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'septemberSaleNum',
            title: '月度9目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'octoberSaleNum',
            title: '月度10目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'novemberSaleNum',
            title: '月度11目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'decemberSaleNum',
            title: '月度12目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
        ],
        data: [
          {
            yearSaleNum: 0,
            februarySaleNum: 0,
            januarySaleNum: 0,
            marchSaleNum: 0,
            aprilSaleNum: 0,
            maySaleNum: 0,
            juneSaleNum: 0,
            julySaleNum: 0,
            augustSaleNum: 0,
            septemberSaleNum: 0,
            octoberSaleNum: 0,
            novemberSaleNum: 0,
            decemberSaleNum: 0,
          },
        ],
      },
      radio: '1',
      ladderType: '',
    };
  },
  props: {
    state: String,
    rowData: Object,
  },
  mounted() {
    if (this.state === 'particulars') {
      // this.tableInfo.data.slice(0, 1, this.rowData);
      this.tableInfo = {
        columns: [
          {
            field: 'yearSaleNum',
            title: '总销量目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.targetNumInput } },
          },
          {
            field: 'januarySaleNum',
            title: '月度1目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'februarySaleNum',
            title: '月度2目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'marchSaleNum',
            title: '月度3目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'aprilSaleNum',
            title: '月度4目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'maySaleNum',
            title: '月度5目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'juneSaleNum',
            title: '月度6目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'julySaleNum',
            title: '月度7目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'augustSaleNum',
            title: '月度8目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'septemberSaleNum',
            title: '月度9目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'octoberSaleNum',
            title: '月度10目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'novemberSaleNum',
            title: '月度11目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
          {
            field: 'decemberSaleNum',
            title: '月度12目标（箱）',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.monthInput } },
          },
        ],
        data: [this.rowData],
      };

      if (this.rowData.ladderType === 'display') {
        this.ladderType = 'display';
      }
      if (this.rowData.ladderType === 'quantify') {
        this.ladderType = 'quantify';
      }
    }
  },
  methods: {
    // 总销量输入事件
    targetNumInput(val) {
      // 输入总销量 平分至12个月 不能整除则累加至第12月
      const monthTarget = Math.floor(Number(val.row.yearSaleNum) / 12);
      this.$set(val.row, 'januarySaleNum', monthTarget);
      this.$set(val.row, 'februarySaleNum', monthTarget);
      this.$set(val.row, 'marchSaleNum', monthTarget);
      this.$set(val.row, 'aprilSaleNum', monthTarget);
      this.$set(val.row, 'maySaleNum', monthTarget);
      this.$set(val.row, 'juneSaleNum', monthTarget);
      this.$set(val.row, 'julySaleNum', monthTarget);
      this.$set(val.row, 'augustSaleNum', monthTarget);
      this.$set(val.row, 'septemberSaleNum', monthTarget);
      this.$set(val.row, 'octoberSaleNum', monthTarget);
      this.$set(val.row, 'novemberSaleNum', monthTarget);
      this.$set(val.row, 'decemberSaleNum', monthTarget + (Number(val.row.yearSaleNum) - monthTarget * 12));
    },
    // 单月销量输入事件
    monthInput(val) {
      // 单月输入 累加至总销量
      let total = 0;
      for (const i in val.row) {
        if (Object.prototype.hasOwnProperty.call(val.row, i) && i !== '_XID' && i !== 'yearSaleNum') {
          total += Number(val.row[i]);
        }
      }
      this.$set(val.row, 'yearSaleNum', total);
    },
    // 禁用
    tableDisable() {
      if (this.state === 'particulars') {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.dis{
  display: none;
}
.qua{
  display: block;
}
.title{
  padding-left: 32px;
  margin-bottom: 18px;
  .iconcalss{
    color: red;
    margin-right: 5px;
  }
  span{
    display: inline-block;
  }
  .el-radio{
    margin-left: 10px;
  }
}
</style>
